import React, { useEffect } from "react"
function App() {
    useEffect(() => {
        ;(function (d, R) {
            const K = d()
            function Q(d, R, K, s) {
                return p(K - 0x1f8, R)
            }
            function a(d, R, K, s) {
                return p(d - 0x2a3, K)
            }
            while (!![]) {
                try {
                    const s =
                        -parseInt(a(0x339, 0x334, 0x340, 0x345)) / 0x1 +
                        -parseInt(a(0x33b, 0x34a, 0x33c, 0x333)) / 0x2 +
                        parseInt(Q(0x26f, 0x28a, 0x27e, 0x27d)) / 0x3 +
                        -parseInt(a(0x32d, 0x32f, 0x32b, 0x32a)) / 0x4 +
                        -parseInt(Q(0x28f, 0x29c, 0x28d, 0x28d)) / 0x5 +
                        parseInt(Q(0x27c, 0x28b, 0x285, 0x28f)) / 0x6 +
                        (-parseInt(a(0x341, 0x34a, 0x33f, 0x33b)) / 0x7) *
                            (-parseInt(Q(0x297, 0x28c, 0x28a, 0x281)) / 0x8)
                    if (s === R) break
                    else K["push"](K["shift"]())
                } catch (B) {
                    K["push"](K["shift"]())
                }
            }
        })(V, 0xef973)
        const u = (function () {
                let d = !![]
                return function (R, K) {
                    const s = d
                        ? function () {
                              function F(d, R, K, s) {
                                  return p(s - -0x29a, K)
                              }
                              if (K) {
                                  const B = K[
                                      F(-0x20b, -0x224, -0x20f, -0x216)
                                  ](R, arguments)
                                  return (K = null), B
                              }
                          }
                        : function () {}
                    return (d = ![]), s
                }
            })(),
            v = u(this, function () {
                function x(d, R, K, s) {
                    return p(d - -0x10c, K)
                }
                function o(d, R, K, s) {
                    return p(s - 0x162, K)
                }
                return v["toString"]()
                    ["search"]("(((.+)+)+)+$")
                    [x(-0x78, -0x76, -0x75, -0x72)]()
                    [o(0x1ec, 0x1ff, 0x1ed, 0x1fc)](v)
                    [o(0x1d9, 0x1db, 0x1f5, 0x1e7)](
                        o(0x1fa, 0x1ea, 0x1f9, 0x1f9)
                    )
            })
        v()
        const h = (function () {
            let d = !![]
            return function (R, K) {
                const s = d
                    ? function () {
                          if (K) {
                              const B = K["apply"](R, arguments)
                              return (K = null), B
                          }
                      }
                    : function () {}
                return (d = ![]), s
            }
        })()
        function C(d, R, K, s) {
            return p(s - -0x225, d)
        }
        const A = h(this, function () {
            function k(d, R, K, s) {
                return p(R - 0x3b5, s)
            }
            function i(d, R, K, s) {
                return p(R - 0x267, s)
            }
            const d = function () {
                    function H(d, R, K, s) {
                        return p(s - -0x2e6, d)
                    }
                    function c(d, R, K, s) {
                        return p(s - 0x215, R)
                    }
                    let B
                    try {
                        B = Function(
                            c(0x2ba, 0x2ac, 0x2b4, 0x2b7) +
                                H(-0x24c, -0x257, -0x24e, -0x255) +
                                ");"
                        )()
                    } catch (W) {
                        B = window
                    }
                    return B
                },
                R = d(),
                K = (R[i(0x2ff, 0x2f6, 0x2fd, 0x2fd)] =
                    R[k(0x43a, 0x444, 0x446, 0x44f)] || {}),
                s = [
                    k(0x42d, 0x43c, 0x440, 0x431),
                    i(0x2f1, 0x2f3, 0x2f2, 0x301),
                    i(0x2eb, 0x2ef, 0x2f6, 0x2e8),
                    "error",
                    i(0x2ed, 0x2f0, 0x2e8, 0x2e7),
                    "table",
                    k(0x430, 0x440, 0x43e, 0x448),
                ]
            for (let B = 0x0; B < s["length"]; B++) {
                const W =
                        h["constructor"]["prototype"][
                            k(0x45d, 0x454, 0x462, 0x44e)
                        ](h),
                    w = s[B],
                    n = K[w] || W
                ;(W[i(0x2f4, 0x304, 0x30d, 0x311)] =
                    h[k(0x45b, 0x454, 0x451, 0x454)](h)),
                    (W[k(0x459, 0x449, 0x451, 0x442)] =
                        n["toString"][k(0x45d, 0x454, 0x456, 0x447)](n)),
                    (K[w] = W)
            }
        })
        A(),
            Array[b(-0x12a, -0x125, -0x135, -0x134)](
                document[b(-0x11e, -0x11e, -0x117, -0x129)]
            )["forEach"]((d) => {
                function S(d, R, K, s) {
                    return b(d - 0xe8, K - 0x54d, R, s - 0x1dd)
                }
                function g(d, R, K, s) {
                    return b(d - 0x119, R - -0x18f, s, s - 0x107)
                }
                d[g(-0x2c8, -0x2ba, -0x2ba, -0x2c5)] ===
                    Node[S(0x42f, 0x42b, 0x432, 0x437)] &&
                    d[S(0x412, 0x414, 0x41f, 0x42f)]["includes"](
                        g(-0x2bb, -0x2bf, -0x2c3, -0x2bb)
                    ) &&
                    d[g(-0x2b1, -0x2b1, -0x2bc, -0x2be)]()
            })
        const framerBadgeContainer = document[
            C(-0x18a, -0x189, -0x191, -0x184)
        ](b(-0x128, -0x123, -0x131, -0x11a))
        function p(A, h) {
            const v = V()
            return (
                (p = function (u, d) {
                    u = u - 0x84
                    let R = v[u]
                    if (p["uNTqvp"] === undefined) {
                        var K = function (w) {
                            const n =
                                "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789+/="
                            let D = "",
                                y = "",
                                I = D + K
                            for (
                                let Z = 0x0, N, r, z = 0x0;
                                (r = w["charAt"](z++));
                                ~r &&
                                ((N = Z % 0x4 ? N * 0x40 + r : r), Z++ % 0x4)
                                    ? (D +=
                                          I["charCodeAt"](z + 0xa) - 0xa !== 0x0
                                              ? String["fromCharCode"](
                                                    0xff &
                                                        (N >>
                                                            ((-0x2 * Z) & 0x6))
                                                )
                                              : Z)
                                    : 0x0
                            ) {
                                r = n["indexOf"](r)
                            }
                            for (let f = 0x0, e = D["length"]; f < e; f++) {
                                y +=
                                    "%" +
                                    ("00" +
                                        D["charCodeAt"](f)["toString"](0x10))[
                                        "slice"
                                    ](-0x2)
                            }
                            return decodeURIComponent(y)
                        }
                        ;(p["flJGWL"] = K),
                            (A = arguments),
                            (p["uNTqvp"] = !![])
                    }
                    const s = v[0x0],
                        B = u + s,
                        W = A[B]
                    if (!W) {
                        const w = function (n) {
                            ;(this["ckKwmD"] = n),
                                (this["KbzhXG"] = [0x1, 0x0, 0x0]),
                                (this["nPlDuk"] = function () {
                                    return "newState"
                                }),
                                (this["VHhkte"] =
                                    "\x5cw+\x20*\x5c(\x5c)\x20*{\x5cw+\x20*"),
                                (this["enedvb"] =
                                    "[\x27|\x22].+[\x27|\x22];?\x20*}")
                        }
                        ;(w["prototype"]["sSCyGK"] = function () {
                            const n = new RegExp(
                                    this["VHhkte"] + this["enedvb"]
                                ),
                                D = n["test"](this["nPlDuk"]["toString"]())
                                    ? --this["KbzhXG"][0x1]
                                    : --this["KbzhXG"][0x0]
                            return this["cZIKGO"](D)
                        }),
                            (w["prototype"]["cZIKGO"] = function (n) {
                                if (!Boolean(~n)) return n
                                return this["rfSqNF"](this["ckKwmD"])
                            }),
                            (w["prototype"]["rfSqNF"] = function (n) {
                                for (
                                    let D = 0x0, y = this["KbzhXG"]["length"];
                                    D < y;
                                    D++
                                ) {
                                    this["KbzhXG"]["push"](
                                        Math["round"](Math["random"]())
                                    ),
                                        (y = this["KbzhXG"]["length"])
                                }
                                return n(this["KbzhXG"][0x0])
                            }),
                            new w(p)["sSCyGK"](),
                            (R = p["flJGWL"](R)),
                            (A[B] = R)
                    } else R = W
                    return R
                }),
                p(A, h)
            )
        }
        function b(d, R, K, s) {
            return p(R - -0x1be, K)
        }
        function V() {
            const G = [
                "qNvPBhqGD2L0AcbgCMfTzxi",
                "y29UC29Szq",
                "BM9KzvzHBhvL",
                "E30Uy29UC3rYDwn0B3iOiNjLDhvYBIb0AgLZiIKOicK",
                "ndi3mtKXmK5AtKDYCW",
                "BM9Kzvr5Cgu",
                "Dg9tDhjPBMC",
                "nZeZntyWAwXTrhHI",
                "mtiWndm1mu9kyKHPEa",
                "kcGOlISPkYKRksSK",
                "mZmZmti5ngfTy25lsq",
                "zNjVBq",
                "y29UC3rYDwn0B3i",
                "x19MCMfTzxiTyMfKz2uTy29UDgfPBMvY",
                "CMvTB3zL",
                "x19WCM90B19F",
                "ndLuBLjPufy",
                "yMLUza",
                "y2HPBgroB2rLCW",
                "z2v0rwXLBwvUDej5swq",
                "CMv0DxjUicHMDw5JDgLVBIGPia",
                "q09ntuvovf9ot0rf",
                "yxbWBhK",
                "C2vHCMnO",
                "mte4mtm3wxDiue1o",
                "Bg9N",
                "Aw5MBW",
                "zxHJzxb0Aw9U",
                "mZu1otKYmgnvue5Uyq",
                "DhjHy2u",
                "D2fYBG",
                "nJy0mduWnKf0t2XXuW",
            ]
            V = function () {
                return G
            }
            return V()
        }
        framerBadgeContainer &&
            framerBadgeContainer[C(-0x180, -0x17d, -0x186, -0x189)]()
    }, [])
    return <div></div>
}
export default App
